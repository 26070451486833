import React, { useContext } from 'react';
import { PublicStore } from '../../../../utils';
import classNames from 'classnames';
import './style.scoped.less';

const IDP = () => {
  // 样式
  const prefixCls = 'IDP-data';
  const classes = classNames(prefixCls);

  // 状态
  const { stateStore } = useContext(PublicStore);
  const { idpNum, idpPublishedNum, totalNum, list } = stateStore;

  return (
    <div className={classes}>
      {
        totalNum === 0 && list.length === 0 ? <>
          <p>{`IDP (已设定/总人数) : -`}</p>
        </> : <>
          <p>{`IDP (已设定/总人数) : ${idpPublishedNum}/${idpNum}`}</p>
        </>
      }
    </div>
  );
};

export default IDP;
