// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagelet-objective-overview-supported[data-v-86719e4e] {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 0;
  border-radius: 12px;
  background: linear-gradient(179deg, rgba(255, 255, 255, 0) -1%, #FFFFFF 36%), linear-gradient(93deg, #FFF3E2 0%, #FFFFFF 52%, #FCC7B8 96%);
  font-size: 12px;
}
.pagelet-objective-overview-supported p[data-v-86719e4e] {
  margin: 0;
}
.pagelet-objective-overview-supported .spin[data-v-86719e4e] {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,0IAA0I;EAC1I,eAAe;AACjB;AACA;EACE,SAAS;AACX;AACA;EACE,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".pagelet-objective-overview-supported[data-v-86719e4e] {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 16px 0;\n  border-radius: 12px;\n  background: linear-gradient(179deg, rgba(255, 255, 255, 0) -1%, #FFFFFF 36%), linear-gradient(93deg, #FFF3E2 0%, #FFFFFF 52%, #FCC7B8 96%);\n  font-size: 12px;\n}\n.pagelet-objective-overview-supported p[data-v-86719e4e] {\n  margin: 0;\n}\n.pagelet-objective-overview-supported .spin[data-v-86719e4e] {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
