// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-chart .ant5-radio-wrapper span:last-child {
  padding-inline-start: 2px;
  padding-inline-end: 0px;
}
.bar-chart .ant5-radio-wrapper-checked > span:last-child {
  font-weight: 500;
  color: #FF6400;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/BarChart/style.less"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".bar-chart .ant5-radio-wrapper span:last-child {\n  padding-inline-start: 2px;\n  padding-inline-end: 0px;\n}\n.bar-chart .ant5-radio-wrapper-checked > span:last-child {\n  font-weight: 500;\n  color: #FF6400;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
