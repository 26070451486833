// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-area[data-v-fac90faf] {
  margin-left: 10px;
  margin-right: 10px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.result-area .columnOrList[data-v-fac90faf] {
  padding: 12px 16px 9px 12px;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/ResultArea/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,OAAO;EACP,kBAAkB;EAClB,qCAAqC;AACvC;AACA;EACE,2BAA2B;EAC3B,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".result-area[data-v-fac90faf] {\n  margin-left: 10px;\n  margin-right: 10px;\n  flex: 1;\n  border-radius: 8px;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n}\n.result-area .columnOrList[data-v-fac90faf] {\n  padding: 12px 16px 9px 12px;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
