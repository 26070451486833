import React, { useContext } from 'react';
import { Avatar } from '@didi/ec-base';
import DebounceSelect from './debounceSelect';
import { PublicStore } from '../../../../utils';
import PageletServices from '../../../../services';
import classNames from 'classnames';
import './style.scoped.less';
import './style.less';

const FilterContent = () => {
  // 样式
  const prefixCls = 'filter-content';
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, stateDispatch, props } = useContext(PublicStore);
  const { searchValue, newHeaders } = stateStore;
  const {
    params: { deptCodes = [] },
  } = props;
  // 搜索员工
  const fetchUserList = async username => {
    // 有输入的情况下
    if (username) {
      try {
        const { data } = await PageletServices.postSearch('', newHeaders, {
          objectiveScene: 'DHR_WORKBENCH_HELP_WINDOW',
          pageNum: Number('1'),
          pageSize: Number('10'),
          deptCodes: deptCodes,
          keyword: username
        });
        return data.list.map(item => ({
          label: (
            <div className="searchItem">
              <div className="head">
                <Avatar
                  size={'small'}
                  src={item?.avatar || "//img-ys011.didistatic.com/static/ddo_web_static/do1_uSZhhctpi1afbFsNH8WL"}
                />
              </div>
              <div className="people">
                <p className="fullName">
                  <span>{item?.name}</span>
                  <span className='line' />
                  <span>{item?.deptName}</span>
                </p>
                <div className="details">
                  <span className="jobID">{item?.emailAddr}</span>
                </div>
              </div>
            </div>
          ),
          text: item?.name,
          value: item?.ownerId,
        }));
      } catch (e) {
        // 抛出异常
        return [];
      }
    }
    return [];
  };
  // 结果请求
  const resultRequest = async params => {
    stateDispatch({ resultLoading: true });
    try {
      const paramsObj = {
        objectiveScene: 'DHR_WORKBENCH_HELP_WINDOW',
        pageNum: Number('1'),
        pageSize: Number('10'),
        deptCodes,
        ...params
      };
      const { data } = await PageletServices.postSearchAndDistribution('', newHeaders, paramsObj);
      const { progressDistribution, pageResult, searchParam } = data;
      const { distributionCode } = searchParam;
      stateDispatch({
        ...searchParam,
        distributions: progressDistribution?.distributions,
        idpNum: progressDistribution?.idpNum,
        idpPublishedNum: progressDistribution?.idpPublishedNum,
        totalNum: progressDistribution?.totalNum,
        distributionName: progressDistribution?.distributions?.find(item => item.code === distributionCode)?.desc,
        list: pageResult?.list,
        hasMore: pageResult?.list.length < pageResult?.total,
        pageNum: Number(pageResult?.pageNum),
        resultLoading: false
      });
    } catch (e) {
      // 抛出异常
      return [];
    }
  };
  // 人员搜索
  const searchPost = (newValue, optionValue) => {
    stateDispatch({
      searchValue: newValue,
      searchName: optionValue?.text
    });
    resultRequest({
      ownerIds: newValue ? [newValue] : []
    });
  };

  return (
    <div className={classes}>
      <DebounceSelect
        value={searchValue || null}
        fetchOptions={fetchUserList}
        onChange={searchPost}
        suffixIcon={<img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_44lPDL4WGzMKYiABjsNh'} />}
      />
    </div>
  );
};

export default FilterContent;
