import React, { useContext } from 'react';
import { Spin } from '@didi/ec-base';
import CycleContent from './components/CycleContent';
import FilterContent from './components/FilterContent';
import ResultArea from './components/ResultArea';
import IDP from './components/IDP';
import { PublicStore } from '../../utils';
import classNames from 'classnames';
import './style.scoped.less';

const PageContent = () => {
  // 样式
  const prefixCls = 'objective-overview-supported-pagelet-content';
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore } = useContext(PublicStore);
  const { resultLoading } = stateStore;

  return (
    <div className={classes}>
      <CycleContent />
      <Spin spinning={resultLoading}>
        <FilterContent />
        <ResultArea />
      </Spin>
      <IDP />
    </div>
  );
};

export default PageContent;
