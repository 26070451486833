import React, { useContext } from 'react';
import { Tooltip, Avatar, Spin } from '@didi/ec-base';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoData from '../NoData';
import { PublicStore, handleJumpUrl, recordTrackEvent } from '../../../../utils';
import PageletServices from '../../../../services'; //页面请求方法
import classNames from 'classnames';
import './style.scoped.less';
import './style.less';

const PersonList = () => {
  // 样式
  const prefixCls = 'person-list';
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, stateDispatch, props } = useContext(PublicStore);
  const {
    distributionName, list,
    hasMore, distributionCode, searchLoading, pageNum, newHeaders
  } = stateStore;
  const {
    params: { apiHost, deptCodes = [] },
  } = props;
  // 上拉加载数据
  const loadMoreData = async () => {
    if (searchLoading) {
      return;
    }
    stateDispatch({ searchLoading: true });
    try {
      const { data } = await PageletServices.postSearch('', newHeaders, {
        objectiveScene: 'DHR_WORKBENCH_HELP_WINDOW',
        pageNum: Number(pageNum + 1),
        pageSize: Number('10'),
        deptCodes: deptCodes,
        progressDistributionCode: distributionCode
      });
      stateDispatch({
        searchLoading: false,
        pageNum: data.pageNum,
        list: [...list, ...data.list],
        hasMore: [...list, ...data.list].length < data?.total,
      });
    } catch (e) {
      stateDispatch({ searchLoading: false });
      console.log(e);
    }
  };

  // 跳转到详情页
  const jumpTo = item => {
    const { empId, deptId } = item;
    const params = {
      empId,
      deptId
    };
    const url = `/new/ddo/others?${handleJumpUrl(params, '')}`
    window.open(`${apiHost}${url}`, '_blank');
    if (apiHost.includes('o.didichuxing.com')) {
      recordTrackEvent({ click_source: Number('2') }); // 记录埋点
    }
  };

  return (
    <div className={classes}>
      <p className="title">{distributionName}:</p>
      {
        list?.length === 0
          ? <NoData text='暂无数据' style={{ minHeight: '180px', paddingTop: '45px', background: 'rgba(245, 245, 245, 0.6)' }} />
          : <div className="personScroll" id="scrollableDiv">
            <InfiniteScroll
              height={180}
              dataLength={list?.length}
              next={loadMoreData}
              hasMore={hasMore}
              loader={<Spin />}
              endMessage={null}
              scrollableTarget="scrollableDiv"
            >
              {list?.map(item => {
                const { performanceId, name, deptName, avatar } = item;
                return (
                  <Tooltip
                    overlayClassName='personTooltip'
                    title={<>
                      <span>{name}</span>
                      <span className='line' />
                      <span>{deptName}</span>
                    </>}
                  >
                    <div
                      className="personItem"
                      key={performanceId}
                      onClick={() => jumpTo(item)}
                    >
                      <div className="head">
                        <Avatar
                          size='small'
                          src={avatar || "//img-ys011.didistatic.com/static/ddo_web_static/do1_uSZhhctpi1afbFsNH8WL"}
                        />
                      </div>
                      <div className="personName">
                        <p className="name">
                          <span>{name}</span>
                          <span className='line' />
                          <span>{deptName}</span>
                        </p>
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </InfiniteScroll>
          </div>
      }
    </div>
  );
};

export default PersonList;
