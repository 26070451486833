// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personTooltip .ant5-tooltip-inner {
  display: flex;
  align-items: center;
}
.personTooltip .ant5-tooltip-inner .line {
  margin: 0 4px;
  width: 1px;
  height: 10px;
  display: inline-block;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.72);
}
.personTooltip .ant5-tooltip-inner span {
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF;
}
.personTooltip .ant5-tooltip-inner span:first-child {
  font-family: '苹方-简';
  font-weight: 500;
  font-size: 14px;
}
.personTooltip .ant5-tooltip-inner span:last-child {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/PersonList/style.less"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,UAAU;EACV,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,qCAAqC;AACvC;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".personTooltip .ant5-tooltip-inner {\n  display: flex;\n  align-items: center;\n}\n.personTooltip .ant5-tooltip-inner .line {\n  margin: 0 4px;\n  width: 1px;\n  height: 10px;\n  display: inline-block;\n  border-radius: 3px;\n  background: rgba(255, 255, 255, 0.72);\n}\n.personTooltip .ant5-tooltip-inner span {\n  font-size: 13px;\n  line-height: 16px;\n  color: #FFFFFF;\n}\n.personTooltip .ant5-tooltip-inner span:first-child {\n  font-family: '苹方-简';\n  font-weight: 500;\n  font-size: 14px;\n}\n.personTooltip .ant5-tooltip-inner span:last-child {\n  font-family: PingFang SC;\n  font-weight: 400;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
