// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-data[data-v-1f75d7b3] {
  flex: 1;
  text-align: center;
  border-radius: 8px;
}
.no-data img[data-v-1f75d7b3] {
  width: 80px;
}
.no-data p[data-v-1f75d7b3] {
  font-family: PingFang SC;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/NoData/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,wBAAwB;EACxB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".no-data[data-v-1f75d7b3] {\n  flex: 1;\n  text-align: center;\n  border-radius: 8px;\n}\n.no-data img[data-v-1f75d7b3] {\n  width: 80px;\n}\n.no-data p[data-v-1f75d7b3] {\n  font-family: PingFang SC;\n  font-size: 12px;\n  line-height: 16px;\n  color: rgba(0, 0, 0, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
