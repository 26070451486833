// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cycle-content[data-v-bea84413] {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.cycle-content .text[data-v-bea84413] {
  margin-right: 10px;
}
.cycle-content .text p[data-v-bea84413] {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.84);
  position: relative;
}
.cycle-content .text p[data-v-bea84413]:last-child {
  margin-top: 0px;
}
.cycle-content .jumpButton[data-v-bea84413] {
  margin-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/CycleContent/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".cycle-content[data-v-bea84413] {\n  margin-left: 16px;\n  margin-right: 16px;\n  margin-bottom: 12px;\n  display: flex;\n  justify-content: space-between;\n}\n.cycle-content .text[data-v-bea84413] {\n  margin-right: 10px;\n}\n.cycle-content .text p[data-v-bea84413] {\n  margin-top: 4px;\n  margin-bottom: 0px;\n  font-size: 12px;\n  line-height: 18px;\n  color: rgba(0, 0, 0, 0.84);\n  position: relative;\n}\n.cycle-content .text p[data-v-bea84413]:last-child {\n  margin-top: 0px;\n}\n.cycle-content .jumpButton[data-v-bea84413] {\n  margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
