import React, { useContext } from 'react';
import { Button } from '@didi/ec-base';
import { PublicStore, recordTrackEvent } from '../../../../utils';
import classNames from 'classnames';
import './style.scoped.less';

const CycleContent = () => {
  // 样式
  const prefixCls = 'cycle-content';
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, props } = useContext(PublicStore);
  const { apolloConfig } = stateStore;
  const { pageLetInfo } = apolloConfig;
  const { cycleBanner } = pageLetInfo;
  const {
    params: { apiHost },
  } = props;
  // 跳转绩效
  const jumpEvent = () => {
    window.open(`${apiHost}/new/ddo/report`, '_blank');
    if (apiHost.includes('o.didichuxing.com')) {
      recordTrackEvent({ click_source: Number('1') }); // 记录埋点
    }
  };

  return (
    <div className={classes}>
      <div className="text">
        {cycleBanner?.map((item, index) => (<p key={index} dangerouslySetInnerHTML={{ __html: item }} />))}
      </div>
      <div className="jumpButton">
        <Button type="primary" size="middle" onClick={jumpEvent}>
          前往目标
        </Button>
      </div>
    </div>
  );
};

export default CycleContent;
