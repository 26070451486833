// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.objective-overview-management-by-post {
  align-items: center;
}
.objective-overview-management-by-post span {
  font-family: PingFang SC, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.84);
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/components/FilterContent/style.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,oCAAoC;EACpC,eAAe;EACf,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".objective-overview-management-by-post {\n  align-items: center;\n}\n.objective-overview-management-by-post span {\n  font-family: PingFang SC, sans-serif;\n  font-size: 12px;\n  line-height: 18px;\n  color: rgba(0, 0, 0, 0.84);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
